import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function useScriberList() {
    const toast = useToast();
    const refListTable = ref(null);
    const tableColumns = [
        { key: 'id', label: 'ID', sortable: false },
        { key: 'title', label: 'Title', sortable: false },
        { key: 'created_by', label: 'By', sortable: false },
        { key: 'is_public',label:'Public', sortable: false },
        { key: 'action',label:'Action', sortable: false },
        
    ];
    const perPage = ref(50);
    const groupQuery = ref('');
    const totals = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('id');
    const isSortDirDesc = ref(true);
    const dataMeta = computed(() => {
        const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
            return {
                from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
                to: perPage.value * (currentPage.value - 1) + localItemsCount,
                of: totals.value,
            }
    });
    const refetchData = () => {
    
        refListTable.value.refresh()
    };
   
   
    watch([ perPage, searchQuery,groupQuery], () => {
      
        refetchData()
    })
    const fetchData = (ctx, callback)=>{
        const offset = perPage.value*(currentPage.value-1);
   
        const userData = JSON.parse(localStorage.getItem('userData'));
        console.log('userData',userData);
        store
        .dispatch('app-crm/fetchsubscriberData', {
            perpage: perPage.value,
            offset:offset,
            created_by:userData.id,
            keyword:searchQuery.value
         
        }).then(async(response) => {
            let data = []
            if(response.data.success){
                data = await response.data.data.map((value)=>{
                    value.is_public = value.is_public?true:false;
                    return value;
                })
            }
           
      
            totals.value = response.data.total
            
            callback(data)
            // callback(invoices)
            //totalInvoices.value = total
        })
        .catch((err) => {
           
            toast({
            component: ToastificationContent,
            props: {
                title: "Error fetching "+err,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
            },
            })
        })
    }
    const changePublic = async(item)=>{
        item.is_public =  item.is_public?1:0;
        const res = await store.dispatch('app-crm/fetchsubscriberUpdatePublicData',item);
        console.log('res', res);
        if(res.data.success){

            toast({
                component: ToastificationContent,
                props: {
                    title: "Update Pulbic Success ",
                    icon: 'AlertTriangleIcon',
                    variant: 'success',
                },
                })
        }else{
            toast({
                component: ToastificationContent,
                props: {
                    title: "Update Failed ",
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
                })

        }


    }
    const deleteData = (id) => {
        //fetchDeleteProperty
        store.dispatch('app-crm/deleteSubscriber',id).then(response=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Delete Success",
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData();
        }).catch(()=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Error Delete Pages",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
    
        })
        //console.log('id', id);
        
      }
      
    return {
        refListTable,
        tableColumns,
        perPage,
        groupQuery,
        totals,
        currentPage,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        dataMeta,
        fetchData,
        changePublic,
        deleteData
    }
}